// Make sure to sort from Newest to Oldest
const data = [
  {
    year: 2023,
    title: "Inspiring the Next Generation at Showboat Atlantic City NJ",
    linkText: "Read Article",
    url: "https://pressofatlanticcity.com/news/local/showboat-asks-kids-what-they-want-to-be-when-they-grow-up-at-career-carnival/article_a3014818-c032-11ed-8ce6-c3fe10ba8d95.html",
    target: false
  },
  {
    year: 2022,
    title: "FDU: Differnt Systems Wins 'Best New Family Business'",
    linkText: "View Winners",
    url: "https://www.fdu.edu/academics/centers-institutes/rothman/family-biz-year/",
    target: false
  },
  {
    year: 2022,
    title: "The Manifest: Website/App Agency Leader in Greater Philadelphia Area",
    linkText: "Read Article",
    url: "https://themanifest.com/press-releases/awards-manifest-philadelphia-2022",
    target: false
  },
  {
    year: 2022,
    title: "Cape May County Chamber Member",
    linkText: "Visit Site",
    url: "https://chamber.capemaycountychamber.com/list/member/f-s-digital-11204",
    target: false
  },
  {
    year: 2022,
    title: "Ventnor Business Association",
    linkText: "View Map",
    url: "https://vbanj.com/map/business/fands-digital-agency",
    target: false
  },
  {
    year: 2020,
    title: "Greater Atlantic City Chamber Member",
    linkText: "Visit Site",
    url: "https://business.acchamber.com/list/member/f-s-digital-2699",
    target: false
  },

]
export default data 