import React, { useEffect, useState } from "react";

const useNumberCounter = (number) => {
  const [count, setCount] = useState("0");

  useEffect(() => {
    let start = 0;
    const duration = 0.1;

    const end = parseInt(number.substring(0, 3));

    if (start === end) return;

    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 100000;

    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + number.substring(3));
      if (start === end) clearInterval(timer);
    }, incrementTime);
  }, []);

  const formatNumber = (num) => {
    if (num >= 1e6) {
      return (
        <>
          {num / 1e6}
          <span>M</span>
        </>
      );
    } else if (num >= 1e3) {
      return (
        <>
          {num / 1e3} <span>K</span>
        </>
      );
    } else {
      return num.toString();
    }
  };

  return formatNumber(count);
};

function SetsUsApartAbout(props) {
  const counter1 = useNumberCounter("96000");
  const counter2 = useNumberCounter("5000");
  const counter3 = useNumberCounter("203000000");
  const counter4 = useNumberCounter("7200");
  return (
    <section className="inverted pt-10 pb-10 pt-lg-15 pb-lg-15 pt-md-15 pb-md-15 pt-sm-10 pb-sm-10 result-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-xl-12">
            <div className="result_desc">
            <span className="sub-title">Results</span>
            <h2 fs-2>
            Lorem ipsum dolor sit amet ipsum
            </h2>
            <p className="fs-7 text-secondary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.
            </p>
            </div>
           
            <div className="row mt-6 justify-content-center">
              <div className="col-md-3">
               <p className="fs-5 counter">{counter1}</p>
                <small className="eyebrow text-muted mb-1 service-advantage-space">
                Lorem ipsum dolor sit
                </small>
              </div>
              <div className="col-md-3">
                <p className="fs-5 counter">{counter2}</p>
                <small className="eyebrow text-muted mb-1 service-advantage-space">
                Lorem ipsum dolor sit
                </small>
              </div>
              <div className="col-md-3">
               <p className="fs-5 counter">{counter3}</p>
                <small className="eyebrow text-muted mb-1 service-advantage-space">
                Lorem ipsum dolor sit
                </small>
              </div>
              
              {/* <div className="col-md-3">
                <small className="eyebrow text-muted mb-1 service-advantage-space">
                  Avg SaaS Subscription Savings
                </small>
                <p className="fs-5">$100k/yr</p>
                <p className="fs-5">{formatNumber(count)}</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


export default SetsUsApartAbout;