import React, { useEffect, useState } from "react";

const useNumberCounter = (number) => {
  const [count, setCount] = useState("0");

  useEffect(() => {
    let start = 0;
    const duration = 0.1;

    const end = parseInt(number.substring(0, 3));

    if (start === end) return;

    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 100000;

    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + number.substring(3));
      if (start === end) clearInterval(timer);
    }, incrementTime);
  }, []);

  const formatNumber = (num) => {
    if (num >= 1e6) {
      return (
        <>
          {num / 1e6}
          <span>M</span>
        </>
      );
    } else if (num >= 1e3) {
      return (
        <>
          {num / 1e3} <span>K</span>
        </>
      );
    } else {
      return num.toString();
    }
  };

  return formatNumber(count);
};

function SetsUsApart(props) {
  const counter1 = useNumberCounter("5");
  const counter2 = useNumberCounter("2");
  const counter3 = useNumberCounter("70");
  const counter4 = useNumberCounter("7200");
  return (
    <section className="inverted pt-10 pb-10 pt-lg-15 pb-lg-15 pt-md-15 pb-md-15 pt-sm-10 pb-sm-10 result-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-xl-12">
            <div className="result_desc">
              <span className="sub-title">Our Results are<span className="differnt-textLogo-white"></span></span>
              <h2 fs-2>
              8 of 10 Companies Select Us After the First Meeting
              </h2>
              <p className="fs-7 text-white">
              Our agility becomes your primary advantage as we prioritize outcomes over individual services, align them with your goals, and optimize team allocation within defined budgets. This approach consistently achieves cost savings while expediting solution delivery in both regulated and non-regulated sectors.
              </p>
            </div>

            <div className="row mt-6">
              <div className="col-md-3">
                <p className="fs-5 counter">${counter1}MM</p>
                <small className="eyebrow text-muted mb-1 service-advantage-space">
                  Total SaaS Subscription Savings Annually
                </small>
              </div>
              <div className="col-md-3">
                <p className="fs-5 counter me-1">{counter2}x</p>
                <small className="eyebrow text-muted mb-1 service-advantage-space">
                  Faster Results Delivery
                </small>
              </div>
              <div className="col-md-3">
                <p className="fs-5 counter">{counter3}%</p>
                <small className="eyebrow text-muted mb-1 service-advantage-space">
                  Clients within Regulated Sectors
                </small>
              </div>
              <div className="col-md-3">
                <p className="fs-5 counter">Zero</p>
                <small className="eyebrow text-muted mb-1 service-advantage-space">
                Unwelcome Surprises
                </small>
              </div>
              {/* <div className="col-md-3">
                <small className="eyebrow text-muted mb-1 service-advantage-space">
                  Avg SaaS Subscription Savings
                </small>
                <p className="fs-5">$100k/yr</p>
                <p className="fs-5">{formatNumber(count)}</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


export default SetsUsApart;