import React from 'react';
import TeamCard from "../../assets/images/AboutImages/team_card-img.jpg"
import BtnArrow from "../../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../../assets/images/button_arrowbottom.svg"


function AboutExecutive(props) {
    return (
        <>
           <section className='about-excutive about-catchy-section'>
               <div className='container'>
                    <div className='excutive-top'>
                        <span className='sub-title'>Meet the Executives</span>
                        <h2>Some catchy heading about the leaders</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='team_box'>
                                <figure><img src={TeamCard} alt=''/></figure>
                                <div className='team_desc'>
                                    <h6>Sage Del Valle</h6>
                                    <p>Working Title & Title</p>
                                    <a href="#" class="button-link">Button link
                                    <div className="arrow_movement">
                                        <img src={BtnArrow} alt=''/>
                                        <img src={BtnArrowbottom} alt=''/>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                        <div className='team_box'>
                        <figure><img src={TeamCard} alt=''/></figure>
                            <div className='team_desc'>
                                <h6>Sage Del Valle</h6>
                                <p>Working Title & Title</p>
                                <a href="#" class="button-link">Button link
                                <div className="arrow_movement">
                                    <img src={BtnArrow} alt=''/>
                                    <img src={BtnArrowbottom} alt=''/>
                                </div>
                                </a>
                            </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                        <div className='team_box'>
                        <figure><img src={TeamCard} alt=''/></figure>
                        <div className='team_desc'>
                                <h6>Sage Del Valle</h6>
                                <p>Working Title & Title</p>
                            <a href="#" class="button-link">Button link
                            <div className="arrow_movement">
                                <img src={BtnArrow} alt=''/>
                                <img src={BtnArrowbottom} alt=''/>
                            </div>
                            </a>
                        </div>
                        </div>
                        </div>
                    </div>
               </div>

           </section>
        </>
    );
}

export default AboutExecutive;