import React from 'react';

function AboutTitle(props) {
    return (
        <>
           <section className='about-top'>
            <div className='container'>
                <div className='about-desc'>
                    <span class="hero-label badge bg-muted-purple text-white rounded-pill mb-3">About Us</span>
                    <h1>Locally Known,<br></br>Globally <span className='text-green'>Respected</span>.</h1>
                </div>
            </div>
           </section>
        </>
    );
}

export default AboutTitle;