import React from 'react';

function AboutDiffernt(props) {
    return (
        <>
           <section className='about-established'>
               <div className='container'>
                <span className='sub-title'>Celebrating 5 Years</span>
                    <div className='row'>
                        <div className='col-md-6'>
                            <h5>Differnt Systems is a vibrant technology consulting, development, and implementation firm. Our expertise lies in bridging technology gaps for established and startup organizations, offering outcome-based solutions that turn aspirations into practical realities.</h5>
                        </div>
                        <div className='col-md-6'>
                            <p>Dedicated to pioneering advancements, we harness tangible innovations such as robotics and building automation, alongside digital expertise in software development and secure cloud infrastructure. This ensures our clients remain at the cutting edge of technology both physically and digitally. Differnt serves diverse industries, catering to both technical and non-technical clients, including healthcare, legal, government, gaming, hospitality, entertainment, media, and logistics.</p>
                        </div>
                    </div>
               </div>

           </section>
        </>
    );
}

export default AboutDiffernt;