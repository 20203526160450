import React, { useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import loadable from '@loadable/component'
import { ParallaxProvider, Parallax } from "react-skrollr";
import Plyr from "plyr"

import CTAvideo from "../assets/videos/web-rotate-1500.mp4"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Award from "../components/Award"
import awards from '../constants/awards'
import keywords from "../constants/keywords"

import Hero from "../components/headers/about-header"
import AboutTitle from "../components/AboutPage/AboutTitle";
import AboutDescription from "../components/AboutPage/AboutDiffernt";
import AboutDifferences from "../components/AboutPage/AboutDifferences";
import AboutAward from "../components/AboutPage/AboutAward";
import SectionAwards from "../components/SectionAwards";
import AboutExecutive from "../components/AboutPage/AboutExecutive";
import Video from '../components/MainVideo';
import SetsUsApart from "../components/SetsUsApart"
import AboutSlider from "../components/AboutPage/AboutSlider";
import SetsUsApartAbout from "../components/AboutPage/SetsUsApartAbout";
import ProcessCards from "../components/OurProcess"

const LoadableParallax = loadable(() => import(/* webpackPrefetch: true */ '../components/parallax/aboutParallax'))

const data = {
  "data-center-top": "@class:inverted bg-color-active;",
  "data-bottom-bottom": "@class:inverted bg-color-active;",
  "data-edge-strategy": "reset"
}

const AboutPage = () => {

  const [ref, inView] = useInView({
    threshold: 0.1, // Change this threshold as needed
    triggerOnce: true, // Only trigger once
  })
  const [plyr, setPlyr] = useState(null)
  const videoRef = useRef(null)

  useEffect(() => {
    if (inView && !plyr) {
      setPlyr(new Plyr(videoRef.current))
    }
  }, [inView, plyr])

  useEffect(() => {

    const elements = document.querySelectorAll('video');

    elements.forEach(function (element) {
      new Plyr(element);
    });

  })


  return (
    <>
      <Layout black={false} >

        <SEO
          title={"About Us | Differnt Systems"}
          description={"Discover how award-winning experts deliver the best technology solutions. From custom software to automation and cloud, we're the partner you need to succeed."}
          customKeywords={keywords.about.keywords}
        />

        <div class="offcanvas-wrap">
          <AboutTitle/>
          <AboutSlider/>
          <AboutDescription/>
          <AboutDifferences/>
          {/*<AboutAward/>*/}
          {/* <SectionAwards
            mainTitle="Our awards"
            secondaryTitle="and recognition"
          /> */}
          <ProcessCards/>
         </div>
      </Layout>

    </>
  )
}

export default AboutPage
