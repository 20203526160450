import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import aboutSlide1 from "../../assets/images/AboutImages/Working-on-code-on-MBP.jpg";
import aboutSlide2 from "../../assets/images/AboutImages/Hands-Working-on-Board.jpg";
import aboutSlide3 from "../../assets/images/AboutImages/Designer-on-iPad-Working.jpg";
import aboutSlide4 from "../../assets/images/AboutImages/Team-working-together-closeup-with-client.jpg";
import aboutSlide5 from "../../assets/images/AboutImages/Engineer-doing-server-system-analysis.jpg";

SwiperCore.use([Pagination]);

const titles = ["Engineers", "Designers", "Consultants", "Analysts", "Developers",];
const paragraphs = [
  "Applying a scientific approach to daily challenges, we scrutinize problems from all angles, meticulously calculating potential outcomes, and delivering only the finest solutions with unwavering passion.",
  "Pursuing design perfection involves seamlessly blending technical understanding with the translation of user interfaces into code, facilitating smooth transitions from ideation to realization. With numerous industry accolades, we prioritize delivering excellence, recognizing that functionality depends on user experience.",
  "Outperforming the competition isn't just our goal—it's our standard. We lead the industry by pushing our team and inspiring clients to dream bigger. Our passion lies in discovering and creating the next big thing, defining our identity.",
  "Uncovering data trends ensures we outpace your competitors, address security threats, and reveal areas for deliverability improvements. We're passionate about leading the way, staying updated on training and tools to make lasting impressions and impactful changes for your organization.",
  "Pushing ourselves to stay ahead with the latest tech stacks, we ensure that our deliverables lead the industry in performance, quality, and scalability. For us, it's not just about coding; it's about delivering an unparalleled, pride-inspiring experience. It's a personal reflection of our love for technology, innovation, and excellence.",
];

function AboutSlider(props) {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleActiveIndexChange = (swiper) => {
    setActiveIndex(swiper.activeIndex % titles.length);
  };

  return (
    <section className="about-slider">
      <div className="slider-left">
        <div className="wrapper">
          <div className="swiper-container" dir="rtl">
            <Swiper
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              modules={[Pagination]}
              pagination={{ clickable: false }}
              spaceBetween={40}
              slidesPerView={4}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              speed={800}
              loop={true}
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 1.2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: "4",
                  spaceBetween: 40,
                },
              }}
              onActiveIndexChange={handleActiveIndexChange}
            >
              <div className="container">
                <SwiperSlide className="capability-box">
                  <img src={aboutSlide1} alt="sliderImage" />
                </SwiperSlide>
                <SwiperSlide className="capability-box">
                  <img src={aboutSlide2} alt="sliderImage" />
                </SwiperSlide>
                <SwiperSlide className="capability-box">
                  <img src={aboutSlide3} alt="sliderImage" />
                </SwiperSlide>
                <SwiperSlide className="capability-box">
                  <img src={aboutSlide4} alt="sliderImage" />
                </SwiperSlide>
                <SwiperSlide className="capability-box">
                  <img src={aboutSlide5} alt="sliderImage" />
                </SwiperSlide>
                {/* <SwiperSlide className="capability-box">
                  <img src={aboutSlide2} alt="sliderImage" />
                </SwiperSlide>
                <SwiperSlide className="capability-box">
                  <img src={aboutSlide3} alt="sliderImage" />
                </SwiperSlide> */}
              </div>
            </Swiper>
          </div>
        </div>
      </div>
      <div className="slider-right">
        <div className="project_desc">
          <p>
            <strong><span className="text-green">It’s Who We Are</span></strong>
          </p>
          <h4>{titles[activeIndex]}</h4>
          <p>{paragraphs[activeIndex]}</p>
          <button className="custom-btn" onClick={() => swiperRef.current.slideNext()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
              <path d="M1.00001 5.99999H12.3137M12.3137 5.99999L8.07107 1.75735M12.3137 5.99999L8.07107 10.2426" stroke="#D6CFE6" strokeWidth="1.5" strokeLinecap="square" />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
}

export default AboutSlider;
